import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "notifications-in-autopatcher"
    }}>{`Notifications in AutoPatcher`}</h1>
    <p>{`This section describes how to configure notifications for your patching. Notifications are configured in patching groups.
Each group may have several notification channels configured (e.g. slack, email, pager duty).
This way one notification can be sent to several slack channels and teams, or pager duty accounts, or emails.`}</p>
    <h2 {...{
      "id": "creating-notification-group"
    }}>{`Creating notification group`}</h2>
    <p>{`First, create the notification group - `}<a parentName="p" {...{
        "href": "https://autopatcher.nordcloudapp.com/notification_groups"
      }}>{`https://autopatcher.nordcloudapp.com/notification_groups`}</a></p>
    <p><img alt="New notification group" src={require("../assets/notification/notification_1.png")} />{`
`}<img alt="New notification group type" src={require("../assets/notification/notification_2.png")} /></p>
    <h2 {...{
      "id": "notification-events"
    }}>{`Notification events`}</h2>
    <p>{`AutoPatcher sends notification about the following events:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Events`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Severity Level`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`event_new`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AutoPatcher created new patching event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There was an error during patching`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`incoming_patching`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Incoming patching`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`not_approved_patching`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AutoPatcher created new patching event which requires approval (or will be approved later automatically in case of a pipeline event)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`plan_modification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DEBUG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patching plan has been modified`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`plan_new`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DEBUG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New patching plan has been created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`plan_deleted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WARNING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patching plan has been deleted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pipeline_modification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DEBUG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patching pipeline has been modified`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pipeline_new`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DEBUG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New patching pipeline has been created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pipeline_deleted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WARNING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patching pipeline has been deleted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`patching_start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patching has been stared`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`patching_finish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SUCCESS or ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patching has been started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`partial_patching_start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Partial patching has been stared`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`partial_patching_finish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SUCCESS or ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Partial patching has been started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine_patching_start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance update has been started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine_patching_finish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SUCCESS or ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance update has been finished`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine_access_err`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance connection error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`machine_not_in_window`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WARNING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance cannot be updated because it is not in the time window`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hook_start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hook has been started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hook_finish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SUCCESS or ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hook has been finished`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`host_hook_start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance hook has been started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`host_hook_finish`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SUCCESS or ERROR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance hook has been finished`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`All finish notifications contain execution status (machine update status, hook result etc.).`}</p>
    <p>{`An incoming_patching notification is sent by default 1 hour before the actual patching is started.
However your can change this time amount by setting `}<inlineCode parentName="p">{`upcoming_notification_time`}</inlineCode>{` field in your plan to a custom value in hours.`}</p>
    <h3 {...{
      "id": "notifications-about-dry-run-plans-and-events"
    }}>{`Notifications about dry run plans and events`}</h3>
    <p>{`AutoPatcher adds a `}<inlineCode parentName="p">{`[DRY RUN]`}</inlineCode>{` tag to the end of a title of every notification when plan or event is configured to scan for available patches. Example:
`}<img alt="dry run" src={require("../assets/notification/dry_run.png")} /></p>
    <h2 {...{
      "id": "notification-channels"
    }}>{`Notification channels`}</h2>
    <h3 {...{
      "id": "slack"
    }}>{`Slack`}</h3>
    <p>{`AutoPatcher can send the notifications to the slack channel. Every notification contains IDs and the links to the related objects so you can easily check its details.`}</p>
    <p>{`Example notifications:
`}<img alt="Slack example" src={require("../assets/notification/slack.png")} /></p>
    <h3 {...{
      "id": "pager-duty"
    }}>{`Pager Duty`}</h3>
    <p>{`AutoPatcher sends all the notifications to the Pager Duty service. Based on these notifications you can create a rule to generate an error alert in the PagerDuty configuration.
Pager Duty example to generate an alert only on errors:`}</p>
    <p><img alt="PD example 1" src={require("../assets/notification/pd1.png")} />{`
`}<img alt="PD example 2" src={require("../assets/notification/pd2.png")} /></p>
    <h3 {...{
      "id": "email"
    }}>{`Email`}</h3>
    <p>{`AutoPatcher can send notifications to configured email. Example:
`}<img alt="Email example" src={require("../assets/notification/email.png")} /></p>
    <h4 {...{
      "id": "customer-friendly-emails"
    }}>{`Customer-friendly emails`}</h4>
    <p>{`For some types of events there's a possibility to send emails in different format which is more customer-friendly. For now the new format is defined only for the following types of events:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`incoming_patching`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patching_start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`patching_finish`}</inlineCode></li>
    </ul>
    <p>{`To use the new format you should check the `}<inlineCode parentName="p">{`Is external`}</inlineCode>{` checkbox when creating email notification:
`}<img alt="External email" src={require("../assets/notification/email_external.png")} /></p>
    <p>{`Below are examples of customer-friendly emails:`}</p>
    <p><img alt="External email incoming" src={require("../assets/notification/external_incoming.png")} /></p>
    <p><img alt="External email started" src={require("../assets/notification/external_started.png")} /></p>
    <p><img alt="External email finished" src={require("../assets/notification/external_finished.png")} /></p>
    <h2 {...{
      "id": "using-cli"
    }}>{`Using CLI`}</h2>
    <h3 {...{
      "id": "adding-notification-group"
    }}>{`Adding notification group:`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli create notification_group --file notify.json
`}</code></pre>
    <p><inlineCode parentName="p">{`notify.json`}</inlineCode>{` example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
    name: "test-group",
    owner: "TEST",
    notifications: [
        {
            channel: "test-channel",
            type: "slack",
            web_hook_url: "webhookurl"
        },
        {
            type: "email",
            email: "x@y.com"
        },
        {
            type: "email",
            email_config: {
                address: "z@w.com",
                is_external: true
            }
        },
        {
            type: "pager duty",
            routing_key: "ct3h84ytc382ty947t4t7243y98bvt2y3vt",
            override_settings: true,
            levels: [],
            events: []
        }
    ],
    events: [],
    levels: []
}
`}</code></pre>
    <ul>
      <li parentName="ul"><em parentName="li">{`type`}</em>{` - notification type: "slack","email", "pager duty"`}</li>
      <li parentName="ul"><em parentName="li">{`web_hook_url`}</em>{` - slack web hook url`}</li>
      <li parentName="ul"><em parentName="li">{`channel`}</em>{` - slack channel routing_key - Pager Duty key`}</li>
      <li parentName="ul"><em parentName="li">{`events`}</em>{` - nofify about specified events. If events array is empty AP sends all notifications`}</li>
      <li parentName="ul"><em parentName="li">{`levels`}</em>{` - notify about specified severity levels. If levels array is empty AP sends all notifications.`}</li>
    </ul>
    <p>{`You can override Notification group filters (events & levels) by adding override_settings: true parameter and providing new settings in the channel configuration.
The full list of the events and severity levels can be found in the `}<inlineCode parentName="p">{`Notification events`}</inlineCode>{` section.`}</p>
    <h3 {...{
      "id": "adding-notification-groups-to-a-plan"
    }}>{`Adding notification groups to a plan`}</h3>
    <p>{`Add the created notification group into the schedule plan. You can add multiple groups:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli update plan --file plan.json --id $PLAN_ID
`}</code></pre>
    <p><inlineCode parentName="p">{`plan.json`}</inlineCode>{` example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
  "cron_window_start": "0 19 * * *",
  "cron_window_end": "0 20 * * *",
  "machines": [
    {
      "id": "XXX",
      "order": 4
    },
    {
      "id": "YYY",
      "order": 3
    }
  ],
  "name": "TEST_PROD_ACCOUNT",
  "owner": "nordcloud",
  "parallel": 1,
  "notification_groups": [
     "34vc5-23v6fc-2v3c65-2c34",
     "GROUP2"
  ]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      